// -----------------
// STATE - This defines the type of data maintained in the Redux store.

import { Reducer } from 'redux';
import { ApplicationAction, AppThunkAction } from './index';
import { setRequestingAction } from './request';
import { notify } from '../notification';
import React from 'react';
import * as Stronghold from '@stronghold/pay-dropin';

export interface CustomerState {
    id: string | null;
    token: string | null;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetCustomerIdAction {
    type: 'SET_CUSTOMER_ID';
    payload: string;
}
export interface SetCustomerTokenAction {
    type: 'SET_CUSTOMER_TOKEN';
    payload: string;
}
export const setCustomerTokenAction = (token: string): SetCustomerTokenAction => ({
    type: 'SET_CUSTOMER_TOKEN',
    payload: token,
});

export type KnownAction = SetCustomerIdAction | SetCustomerTokenAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    findCustomer: (): AppThunkAction<ApplicationAction> => async (dispatch, getState) => {
        const apiHost = getState().configuration.config.apiHost;
        const publishableKey = getState().configuration.aggregator?.publishableKey || '';

        dispatch(setRequestingAction('find_customer'));
        try {
            const strongholdPay = Stronghold.Pay({
                host: apiHost,
                environment: Stronghold.ENVIRONMENT.sandbox,
                publishableKey: publishableKey,
            });
            strongholdPay.findCustomer({
                onSuccess: (customer) => {
                    notify(
                        <span>
                            Customer <strong>{customer.customer_details.id}</strong> added.
                        </span>,
                    );
                    dispatch({ type: 'SET_CUSTOMER_ID', payload: customer.customer_details.id });
                },
                onReady: () => dispatch(setRequestingAction()),
            });
        } finally {
            dispatch(setRequestingAction());
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: CustomerState = {
    id: null,
    token: null,
};

export const reducer: Reducer<CustomerState, KnownAction> = (
    state: CustomerState = unloadedState,
    action: KnownAction,
): CustomerState => {
    switch (action.type) {
        case 'SET_CUSTOMER_ID':
            return {
                ...state,
                id: action.payload,
            };
        case 'SET_CUSTOMER_TOKEN':
            return {
                ...state,
                token: action.payload,
            };
        default:
            return state;
    }
};
