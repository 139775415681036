// -----------------
// STATE - This defines the type of data maintained in the Redux store.

import React from 'react';
import { notify } from '../notification';
import { Reducer } from 'redux';
import * as Stronghold from '@stronghold/pay-dropin';
import client from '../client';
import { setCustomerTokenAction } from './customer';
import { ApplicationAction, AppThunkAction } from './index';
import { setRequestingAction } from './request';
import { PaymentSource } from '@stronghold/pay-dropin';

export interface PaymentSourceState {
    paymentSource: PaymentSource | null;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetPaymentSourceAction {
    type: 'SET_PAYMENT_SOURCE';
    payload: PaymentSource;
}
export const setPaymentSourceAction = (paymentSource: PaymentSource): SetPaymentSourceAction => ({
    type: 'SET_PAYMENT_SOURCE',
    payload: paymentSource,
});

export type KnownAction = SetPaymentSourceAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    addPaymentSource: (): AppThunkAction<ApplicationAction> => async (dispatch, getState) => {
        const apiHost = getState().configuration.config.apiHost;
        const publishableKey = getState().configuration.aggregator?.publishableKey || '';

        dispatch(setRequestingAction('add_payment_source'));
        try {
            const customer = await client.getCustomerToken();
            dispatch({ type: 'SET_CUSTOMER_ID', payload: customer.id });
            dispatch(setCustomerTokenAction(customer.token));

            const strongholdPay = Stronghold.Pay({
                host: apiHost,
                environment: Stronghold.ENVIRONMENT.sandbox,
                publishableKey: publishableKey,
            });
            strongholdPay.addPaymentSource(customer.token, {
                onSuccess: (paymentSource) => {
                    notify(
                        <span>
                            Payment source <strong>{paymentSource.label}</strong> added.
                        </span>,
                    );
                    dispatch({ type: 'SET_PAYMENT_SOURCE', payload: paymentSource });
                },
                onReady: () => dispatch(setRequestingAction()),
            });
        } finally {
            dispatch(setRequestingAction());
        }
    },

    updatePaymentSource: (): AppThunkAction<ApplicationAction> => async (dispatch, getState) => {
        const apiHost = getState().configuration.config.apiHost;
        const publishableKey = getState().configuration.aggregator?.publishableKey || '';
        const paymentSource = getState().paymentSource.paymentSource || null;
        const paymentSourceId = paymentSource?.id || '';
        const customerId = getState().customer.id || '';
        const aggregatorName = getState().configuration.aggregator?.name || 'plaid';

        dispatch(setRequestingAction('update_payment_source'));
        try {
            await client.resetPaymentSource(paymentSourceId, aggregatorName);
            const customer = await client.getCustomerToken(customerId);
            dispatch({ type: 'SET_CUSTOMER_ID', payload: customer.id });
            dispatch(setCustomerTokenAction(customer.token));

            dispatch(setRequestingAction());
            const strongholdPay = Stronghold.Pay({
                host: apiHost,
                environment: Stronghold.ENVIRONMENT.sandbox,
                publishableKey: publishableKey,
            });
            strongholdPay.updatePaymentSource(customer.token, {
                paymentSourceId,
                onSuccess: () => {
                    notify(
                        <span>
                            Credentials of the payment source <strong>{paymentSource?.label}</strong> updated.
                        </span>,
                    );
                },
                onReady: () => dispatch(setRequestingAction()),
            });
        } finally {
            dispatch(setRequestingAction());
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: PaymentSourceState = {
    paymentSource: null,
};

export const reducer: Reducer<PaymentSourceState, KnownAction> = (
    state: PaymentSourceState = unloadedState,
    action: KnownAction,
): PaymentSourceState => {
    switch (action.type) {
        case 'SET_PAYMENT_SOURCE':
            return {
                ...state,
                paymentSource: action.payload,
            };
        default:
            return state;
    }
};
