import { connect, MapStateToPropsParam } from 'react-redux';
import { Anchor, TableOfContents, TableOfContentsElement } from '../components/TableOfContents';
import SideBySideLayout from '../components/SideBySideLayout';
import LeftSide from '../components/LeftSide';
import RightSide from '../components/RightSide';
import ReferenceLink from '../components/ReferenceLink';
import { Code, Snippet } from '../components/CodeSnippet';
import React from 'react';
import AddPaymentSourceButton from '../components/sdk/AddPaymentSourceButton';
import UpdatePaymentSourceButton from '../components/sdk/UpdatePaymentSourceButton';
import AddChargeButton from '../components/sdk/AddChargeButton';
import AddTipButton from '../components/sdk/AddTipButton';
import { ApplicationState } from '../store';
import AggregatorCredentialsAlert from '../components/AggregatorCredentialsAlert';
import FindCustomerButton from '../components/sdk/FindCustomerButton';

const elements: TableOfContentsElement[] = [
    {
        text: 'Introduction',
    },
    {
        text: 'How it works?',
    },
    {
        text: 'Customer Token Generation',
        size: '2',
    },
    {
        text: 'Methods',
    },
    {
        text: 'Add Payment Source',
        size: '2',
    },
    {
        text: 'Update Payment Source',
        size: '2',
    },
    {
        text: 'Charge',
        size: '2',
    },
    {
        text: 'Tip',
        size: '2',
    },
    {
        text: 'Find Customer',
        size: '2',
    },
];

interface StateProps {
    paymentSourceLabel: string | null;
}

interface OwnProps {}

interface DispatchProps {}

type Props = StateProps & OwnProps & DispatchProps;

class SDK extends React.PureComponent<Props> {
    render() {
        let anchor = 0;
        return (
            <SideBySideLayout>
                <LeftSide>
                    <TableOfContents elements={elements} />
                </LeftSide>
                <RightSide>
                    <div>
                        <h1>
                            SDK <small className="text-primary">Examples</small>
                        </h1>
                        <p className="lead my-4">Integration of our Javascript SDK</p>
                        <p>
                            Providing functionality that allows you to add payment processing to your web Application.
                        </p>

                        <Anchor {...elements[anchor++]} />
                        <p>
                            Every method call will result by opening a popup for a specifically targeted customer. Which
                            allows us to be aware of the customer information when interacting with our service.
                        </p>

                        <Anchor {...elements[anchor++]} />
                        <p>
                            Our JS Library allows you instantiate a client using a <strong>publishable key</strong>.
                            You'll be able to find your publishable key formatted as <code>pk_sandbox_...</code> on the{' '}
                            <strong>Developers</strong> page of the <ReferenceLink to={'dashboard'} />.
                        </p>
                        <Snippet>
                            <Code
                                code={
                                    'const client = Stronghold.Pay({\n' +
                                    "   publishableKey: 'pk_sandbox_...',\n" +
                                    "   environment: 'sandbox' // Or live\n" +
                                    '});'
                                }
                            />
                        </Snippet>
                        <p>
                            Methods can then be called using a <strong>customer token</strong>. This token identifies
                            the customer using our SDKs.
                        </p>
                        <p>
                            You can find more information about setting up and using the JS SDK in our{' '}
                            <ReferenceLink to={'api_docs'} route="stronghold-pay-js" />.
                        </p>

                        <Anchor {...elements[anchor++]} />
                        <p>
                            A customer token is required in order to make requests to our JS library. This token has a
                            30 minute life span and is customer specific.
                        </p>
                        <p>
                            Please follow the{' '}
                            <ReferenceLink to={'api_docs'} route="api-reference-v2/customers/createcustomertoken" /> to
                            discover how a customer token generated.
                        </p>

                        <Anchor {...elements[anchor++]} />
                        <p>
                            You can try all the methods available below. The data used behind the scenes for the
                            examples is randomly generated and kept private.
                        </p>

                        <Anchor {...elements[anchor++]} />
                        <p>
                            The <code>addPaymentSource</code> method will take the customer through the addition of a
                            new payment source.
                        </p>
                        <p>A payment source is needed later on in order to perform any kind of payment.</p>
                        <Snippet>
                            <Code
                                code={
                                    "strongholdPay.addPaymentSource('eyJhbGciOiJIUzI1NiIsInR5cCI6I...', {\n" +
                                    '   onSuccess: function (paymentSource) {\n' +
                                    '       // Newly created payment source\n' +
                                    '   },\n' +
                                    '});'
                                }
                            />
                        </Snippet>
                        <AggregatorCredentialsAlert />
                        <AddPaymentSourceButton id="add-payment-source-button">Try it</AddPaymentSourceButton>

                        <Anchor {...elements[anchor++]} />
                        <p>
                            The <code>updatePaymentSource</code> method lets the customer update their credentials for
                            an existing payment source.
                        </p>
                        <p>
                            Payment sources need to be synchronized regularly as up-to-date data is required for
                            creating a successful payment. It is possible that a customer needs to re-enter their
                            credentials in order to reactivate the synchronization.
                        </p>
                        <Snippet>
                            <Code
                                code={
                                    "strongholdPay.updatePaymentSource('eyJhbGciOiJIUzI1NiIsInR5cCI6I...', {\n" +
                                    "   paymentSourceId: 'payment_source_...', // Existing payment source which needs to be updated\n" +
                                    '   onSuccess: function () {\n' +
                                    '       // Payment has been updated\n' +
                                    '   },\n' +
                                    '});'
                                }
                            />
                        </Snippet>

                        <AggregatorCredentialsAlert />
                        <div
                            className="alert alert-warning"
                            data-sh="payment-source-required"
                            hidden={!!this.props.paymentSourceLabel}
                        >
                            In order to update a payment source's credentials, you need to add one first.
                            <div className="mt-3">
                                <AddPaymentSourceButton id="add-payment-source-button-for-update" />
                            </div>
                        </div>
                        <div
                            className="alert alert-primary"
                            data-sh="payment-source-configured"
                            hidden={!this.props.paymentSourceLabel}
                        >
                            Payment Source{' '}
                            <strong data-sh="payment-source-label">{this.props.paymentSourceLabel}</strong> will be
                            used.
                        </div>

                        <UpdatePaymentSourceButton id="update-payment-source-button">Try it</UpdatePaymentSourceButton>

                        <Anchor {...elements[anchor++]} />
                        <p>
                            The method <code>charge</code> take a customer through payment authorization. A summary of
                            the charge that will be created is shown to the customer.
                        </p>
                        <p>
                            This action will create a charge for the customer using one of their payment sources.
                            Optionally, it is possible to associate a tip to the charge.
                        </p>
                        <Snippet>
                            <Code
                                code={
                                    "strongholdPay.charge('eyJhbGciOiJIUzI1NiIsInR5cCI6I...', {\n" +
                                    '    charge: {\n' +
                                    '       amount: 8950, // $89.50, total amount which includes the convenience fees\n' +
                                    "       paymentSourceId: 'payment_source_...',\n" +
                                    '    },\n' +
                                    '    authorizeOnly: false, // Set to true to not capture the charge immediately and create it as `authorized`.\n' +
                                    '    tip: { // Optional\n' +
                                    '       amount: 300 // $3.00,\n' +
                                    "       beneficiaryName: 'John', // The budtender getting the tip\n" +
                                    '    },\n' +
                                    '    onSuccess: function (charge) {\n' +
                                    '       // Newly created charge\n' +
                                    '    },\n' +
                                    '});'
                                }
                            />
                        </Snippet>

                        <div className="d-flex">
                            <AddChargeButton id="charge-button" className="mr-3">
                                Try it
                            </AddChargeButton>
                            <AddChargeButton id="charge-button-with-tip" tipAmount={300}>
                                Try it with a tip!
                            </AddChargeButton>
                        </div>

                        <Anchor {...elements[anchor++]} />
                        <p>
                            The <code>tip</code> method creates a tip using a specific call. It is very similar to the{' '}
                            <code>charge</code> method.
                        </p>
                        <p>
                            <strong>Tip creation requires a charge to be created first.</strong> This is because a tip
                            must be associated with a previous payment.
                        </p>

                        <Snippet>
                            <Code
                                code={
                                    "strongholdPay.tip('eyJhbGciOiJIUzI1NiIsInR5cCI6I...', {\n" +
                                    '    tip: {\n' +
                                    '       amount: 300 // $3.00,\n' +
                                    "       paymentSourceId: 'payment_source_...',\n" +
                                    "       chargeId: 'charge_...', // The original charge the tip is made for\n" +
                                    "       beneficiaryName: 'John', // The budtender getting the tip\n" +
                                    '    },\n' +
                                    '    authorizeOnly: false, // Set to true to not capture the tip immediately and create it as `authorized`.\n' +
                                    '    onSuccess: function (tip) {\n' +
                                    '       // Newly created tip\n' +
                                    '    },\n' +
                                    '});'
                                }
                            />
                        </Snippet>

                        <AddTipButton id="tip-button">Try it</AddTipButton>

                        <Anchor {...elements[anchor++]} />
                        <p>
                            The <code>findCustomer</code> method creates a new customer or finds an existing customer.
                        </p>

                        <p>
                            The method returns an object that contains the customer's details and a new customer token
                            that can be passed into other methods that require a customer token.
                        </p>

                        <Snippet>
                            <Code
                                code={
                                    'strongholdPay.findCustomer({\n' +
                                    '    isLogin: false, // Optional value. Set to true to start on the login page. Otherwise the sign up page will be initially loaded.\n' +
                                    '    onSuccess: function (customer) {\n' +
                                    '       // Newly created customer or existing customer, along with a valid customer token \n' +
                                    '    },\n' +
                                    '});'
                                }
                            />
                        </Snippet>

                        <FindCustomerButton id="find-customer-button">Try it</FindCustomerButton>
                    </div>
                </RightSide>
            </SideBySideLayout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state: ApplicationState) => {
    return {
        paymentSourceLabel: state.paymentSource.paymentSource?.label || null,
    };
};

export default connect(mapStateToProps)(SDK);
